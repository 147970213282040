.line-1{line-height:1!important}
.align-end{margin-left:auto}
.align-start{margin-left:0}
.align-bottom{margin-top:auto;margin-bottom:0}
.align-top{margin-top:0;margin-bottom:auto}
.bold{font-weight:bold}
.mh-100vh{max-height: 100vh !important;}
.mw-100vw{max-width: 100vw !important;}
.z-index-9{z-index:9}
.z-index-20{z-index:20}
.p-0{padding:0px}   .pt-0{padding-top:0px}  .pr-0{padding-right:0px}  .pb-0{padding-bottom:0px} .pl-0{padding-left:0px}
.p-05{padding:4px}  .pt-05{padding-top:4px}  .pr-05{padding-right:4px}  .pb-05{padding-bottom:4px} .pl-05{padding-left:4px}
.p-1{padding:8px}   .pt-1{padding-top:8px}  .pr-1{padding-right:8px}  .pb-1{padding-bottom:8px} .pl-1{padding-left:8px}
.p-15{padding:12px} .pt-15{padding-top:12px}  .pr-15{padding-right:12px}  .pb-15{padding-bottom:12px} .pl-15{padding-left:12px}
.p-2{padding:16px}  .pt-2{padding-top:16px}  .pr-2{padding-right:16px}  .pb-2{padding-bottom:16px} .pl-2{padding-left:16px}
.p-3{padding:24px}  .pt-3{padding-top:24px}  .pr-3{padding-right:24px}  .pb-3{padding-bottom:24px} .pl-3{padding-left:24px}
.p-4{padding:32px}  .pt-4{padding-top:32px}  .pr-4{padding-right:32px}  .pb-4{padding-bottom:32px} .pl-4{padding-left:32px}
.p-5{padding:40px}  .pt-5{padding-top:40px}  .pr-5{padding-right:40px}  .pb-5{padding-bottom:40px} .pl-5{padding-left:40px}
.p-6{padding:48px}  .pt-6{padding-top:48px}  .pr-6{padding-right:48px}  .pb-6{padding-bottom:48px} .pl-6{padding-left:48px}
.p-7{padding:56px}  .pt-7{padding-top:56px}  .pr-7{padding-right:56px}  .pb-7{padding-bottom:56px} .pl-7{padding-left:56px}
.p-8{padding:64px}  .pt-8{padding-top:64px}  .pr-8{padding-right:64px}  .pb-8{padding-bottom:64px} .pl-8{padding-left:64px}
.p-9{padding:72px}  .pt-9{padding-top:72px}  .pr-9{padding-right:72px}  .pb-9{padding-bottom:72px} .pl-9{padding-left:72px}
.p-10{padding:80px} .pt-10{padding-top:80px}  .pr-10{padding-right:80px}  .pb-10{padding-bottom:80px} .pl-10{padding-left:80px}
.m-0{margin:0px}   .mt-0{margin-top:0px}  .mr-0{margin-right:0px}  .mb-0{margin-bottom:0px} .ml-0{margin-left:0px}
.m-05{margin:4px}  .mt-05{margin-top:4px}  .mr-05{margin-right:4px}  .mb-05{margin-bottom:4px} .ml-05{margin-left:4px}
.m-1{margin:8px}   .mt-1{margin-top:8px}  .mr-1{margin-right:8px}  .mb-1{margin-bottom:8px} .ml-1{margin-left:8px}
.m-15{margin:12px} .mt-15{margin-top:12px}  .mr-15{margin-right:12px}  .mb-15{margin-bottom:12px} .ml-15{margin-left:12px}
.m-2{margin:16px}  .mt-2{margin-top:16px}  .mr-2{margin-right:16px}  .mb-2{margin-bottom:16px} .ml-2{margin-left:16px}
.m-3{margin:24px}  .mt-3{margin-top:24px}  .mr-3{margin-right:24px}  .mb-3{margin-bottom:24px} .ml-3{margin-left:24px}
.m-4{margin:32px}  .mt-4{margin-top:32px}  .mr-4{margin-right:32px}  .mb-4{margin-bottom:32px} .ml-4{margin-left:32px}
.m-5{margin:40px}  .mt-5{margin-top:40px}  .mr-5{margin-right:40px}  .mb-5{margin-bottom:40px} .ml-5{margin-left:40px}
.m-6{margin:48px}  .mt-6{margin-top:48px}  .mr-6{margin-right:48px}  .mb-6{margin-bottom:48px} .ml-6{margin-left:48px}
.m-7{margin:56px}  .mt-7{margin-top:56px}  .mr-7{margin-right:56px}  .mb-7{margin-bottom:56px} .ml-7{margin-left:56px}
.m-8{margin:64px}  .mt-8{margin-top:64px}  .mr-8{margin-right:64px}  .mb-8{margin-bottom:64px} .ml-8{margin-left:64px}
.m-9{margin:72px}  .mt-9{margin-top:72px}  .mr-9{margin-right:72px}  .mb-9{margin-bottom:72px} .ml-9{margin-left:72px}
.m-10{margin:80px} .mt-10{margin-top:80px}  .mr-10{margin-right:80px}  .mb-10{margin-bottom:80px} .ml-10{margin-left:80px}
.mx-25{margin-left:25px!important;margin-right:25px!important}
.mt-100{margin-top:100px} .mt-120{margin-top:120px} .mt-150{margin-top:150px}
.pt-120{padding-top:120px} .pt-150{padding-top:150px}
.px-0{padding-left:0px;padding-right:0px} .mx-0{margin-left:0px;margin-right:0px}
.px-1{padding-left:8px;padding-right:8px} .mx-1{margin-left:8px;margin-right:8px}
.px-15{padding-left:12px;padding-right:12px} .mx-15{margin-left:12px;margin-right:12px}
.px-2{padding-left:16px;padding-right:16px} .mx-2{margin-left:16px;margin-right:16px}
.px-3{padding-left:24px;padding-right:24px} .mx-3{margin-left:24px;margin-right:24px}
.px-4{padding-left:32px;padding-right:32px} .mx-4{margin-left:32px;margin-right:32px}
.mx-auto{margin-left:auto;margin-right:auto}    .my-auto{margin-top:auto;margin-bottom:auto}
.px-auto{padding-left:auto;padding-right:auto}  .py-auto{padding-top:auto;padding-bottom:auto}
.relative{position:relative} 
.absolute{position: absolute;top: 0;bottom: 0;right: 0;left: 0}
.p-fixed{position: fixed;top: 0;bottom: 0;right: 0;left: 0}
.absolute-center{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%)}
.absolute-100{position: absolute;top: 0;left: 0; width:100vw;height:100vh}
.min-h-100{min-height:100%}
.full-image{height: auto; width: 100%; display: block; object-fit: cover}
.full-image.mobile{width: 100%;height: 100%;object-fit: cover}
.border-width-0{border-width: 0}
@keyframes fadeScale {from {opacity: 0;-webkit-transform:scale(0.5);transform:scale(0.5)}to {opacity: 1;-webkit-transform: none;transform: none;} } 
@keyframes fadeInOut {from {opacity: 0}to {opacity: 1} }
@keyframes miniLogo {0% { opacity: 0 } 50% { opacity: 1 } 100% { opacity: 0 }}
@keyframes fullLogo {from { width: 0% } to { width:100% }}
@keyframes progress-red {from { width: 0% } to { width:100% }}
.mini-logo #square{
    animation-name: miniLogo;
    animation-duration: 4.0s; 
    animation-timing-function: linear; 
    animation-iteration-count: infinite; 
    animation-play-state: running;
}
span.full-logo{
    position:absolute;
    overflow:hidden;
    animation-name: fullLogo;
    animation-duration: 4.0s; 
    animation-timing-function: linear; 
    animation-iteration-count: forwards;
}
div.progress-red{
    position:absolute;
    overflow:hidden;
    height:1px;
    background-color: #7A303F;
    animation-name: progress-red;
    animation-duration: 10.0s; 
    animation-timing-function: linear; 
    animation-iteration-count: 1;
}
.fixed-video {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: fill !important;    
}
@media(max-width: 812px){
    .svg-contain svg{width:100%;height:100%}
    .fixed-video{
        object-fit: none !important;
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        transform: scale(1)  translateX(calc((100% - 100vw) / 1.5))}
}

.ant-carousel .slick-initialized .slick-slide {
  transform: scale(0.6);
  transition: 0.3s;
  opacity: 0.5;
}
.ant-carousel .slick-list .slick-slide.slick-active.slick-current {
  transform: scale(1);
  opacity: 1;
}
.slick-current + .slick-slide ~ .slick-slide{
  transform: scale(0.4);
  opacity: 0.25;
}
.slick-slide{
  transform: scale(0.4);
  opacity: 0.25;
}

.next-icon {
  color: inherit;
  height: .9em;
  margin-left: .5em;
}

.next-icon-major-margin {
  margin-left: 1.5em;
  font-size: 16px;
}

.enter-tour-button {
  font-size: 10px !important;
  letter-spacing: 0.9px !important;
}

.mw-667{max-width: 667px}

.left-map{
  background: "#000000";
  opacity:"0.9";
  left: "12.50%";
  right: "12.5%";
  top: "20%";
  bottom: "10%";
  height: 'auto';
  width: 'auto'
}

.pointer{cursor: pointer}

.main-nav{
  width: 100%;
}
.main-nav ul {
    align-items: flex-start;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
}

.main-nav ul span {
  opacity: 0.8;
  display: block;
  font-size: 1rem;
  font-weight: normal;
  margin: 0.25rem 0 0 0;
}
.main-nav ul li {
    padding: 0 6px 10px 6px;
    font-size: 20px;
    max-width: 140px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    color: #545454;
    text-transform: uppercase;
}

.main-nav ul li :hover{
  cursor: pointer;
  color: #fff;
}
.main-nav ul li.active{
  color: #fff;
}
.main-nav ul li span {
  border-bottom: 1px solid;
  line-height: 18px;
}
.main-nav ul li:focus {
      color: white;
  }
.main-nav ul li:focus span {
    color: white; 
  }
 

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity:  0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity:  0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity:  0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity:  0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity:  0; }
}

.fadeout-transition{
  -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeout 1s; /* Firefox < 16 */
        -ms-animation: fadeout 1s; /* Internet Explorer */
         -o-animation: fadeout 1s; /* Opera < 12.1 */
            animation: fadeout 1s;
}

.fadein-transition{
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

.arrow-left {
  position: absolute;
  right: -0.85em;
  top: 0.4em;
  font-size: 1.1em;
}

.arrow-left-music {
  position: absolute;
  right: 1em;
  top: 1.2em;
  font-size: 1.1em;
}

.button-changed:hover {
  background-color: '#703540';
}

.react-icons-hover:hover {
  color: '#000';
}
@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}


@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

/* Firefox < 16 */
@-moz-keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

/* Internet Explorer */
@-ms-keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

/* Opera < 12.1 */
@-o-keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

.chat-icon{
  position: relative;
  -webkit-animation: MoveUpDown 2s infinite  normal ease-out;
  animation: MoveUpDown 2s infinite  normal ease-out;
  cursor: pointer;
}

.chat-icon :hover .background-chat-icon{
  fill: #703540;
}


@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@-moz-keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@-webkit-keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@-o-keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@-ms-keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.hotspot-product-icon{
  position: relative;
  -webkit-animation: pulse 2s infinite  normal ease-out;
  animation: pulse 2s infinite  normal ease-out;
}

.info-icon :hover .background-info-icon{
  fill: #703540;
  cursor: pointer;
}

.error-message {
  color: red;
  font-size: 5em;
}

@keyframes scale {
	0% {
		transform: scale(1);
  }
  
	100% {
		transform: scale(1.2);
	}
}

@-moz-keyframes scale {
	0% {
		transform: scale(1);
  }
  
	100% {
		transform: scale(1.2);
	}
}

@-webkit-keyframes scale {
	0% {
		transform: scale(1);
  }
  
	100% {
		transform: scale(1.2);
	}
}

@-o-keyframes scale {
	0% {
		transform: scale(1);
  }
  
	100% {
		transform: scale(1.2);
	}
}

@-ms-keyframes scale {
	0% {
		transform: scale(1);
  }
  
	100% {
		transform: scale(1.2);
	}
}

@keyframes borderchange{
	0%, 100% {
		border-color: #6b6b6b !important;
  }
  
	50% {
		border: #b7b7b7 !important;
	}
}

@-moz-keyframes borderchange{
	0%, 100% {
		border-color: #6b6b6b !important;
  }
  
	50% {
		border: #b7b7b7 !important;
	}
}

@-webkit-keyframes borderchange{
	0%, 100% {
		border-color: #6b6b6b !important;
  }
  
	50% {
		border: #b7b7b7 !important;
	}
}

@-o-keyframes borderchange{
	0%, 100% {
		border-color: #6b6b6b !important;
  }
  
	50% {
		border: #b7b7b7 !important;
	}
}

@-ms-keyframes borderchange{
	0%, 100% {
		border-color: #6b6b6b !important;
  }
  
	50% {
		border: #b7b7b7 !important;
	}
}

.navigation-max-width{
  max-width: 100%;
}

@media (max-width: 767px) {
  .navigation-max-width{
    max-width: 500px;
  }
}

.ant-drawer-content-wrapper{
  max-width: 100%;
}

@media (min-width: 1200px){
  
  .ant-drawer-content-wrapper{
    max-width: 600px;
  }
}

.product-container-map{
  padding-left: 0 !important;
}

.terms{
  color: #777777;
  opacity: 0.5;
  cursor: pointer;
  font-family:DIN2014;
}